import React from 'react';

class Socials extends React.Component {
  render() {
    return(
      <ul className="social-network social-circle">
        <li>
          <a href="https://t.me/Temtumofficial" className="" rel="external noopener noreferrer" target="_blank" title="Telegram" data-toggle="tooltip">
            <i className="fab fa-telegram-plane"/>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/wearetemtum" className="" rel="external noopener noreferrer" target="_blank" title="Twitter" data-toggle="tooltip">
            <i className="fab fa-twitter"/>
          </a>
        </li>
        {/*
        <li>
          <a href="https://discord.gg/hs3v4g4" className="" rel="external noopener noreferrer" target="_blank" title="Discord" data-toggle="tooltip">
            <i className="fab fa-discord"/>
          </a>
        </li>
        */}
        <li>
          <a href="https://www.reddit.com/r/temtum" className="" rel="external noopener noreferrer" target="_blank" title="Reddit" data-toggle="tooltip">
            <i className="fab fa-reddit-alien"/>
          </a>
        </li>
        <li>
          <a href="https://medium.com/temtum" className="" rel="external noopener noreferrer" target="_blank" title="Medium" data-toggle="tooltip">
            <i className="fab fa-medium"/>
          </a>
        </li>
        {/*<li>
          <a href="https://www.facebook.com/temtumTeam" className="" rel="external noopener noreferrer" target="_blank" title="Facebook" data-toggle="tooltip">
            <i className="fab fa-facebook-f"/>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/temtum/" className="" rel="external noopener noreferrer" target="_blank" title="LinkedIn" data-toggle="tooltip">
            <i className="fab fa-linkedin-in"/>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCxEJq-piVp83Cm3yAOtQgkg" className="" rel="external noopener noreferrer" target="_blank" title="Youtube" data-toggle="tooltip">
            <i className="fab fa-youtube"/>
          </a>
        </li>
        */}
        <li>
          <a href="https://github.com/temtum" className="" rel="external noopener noreferrer" target="_blank" title="Github" data-toggle="tooltip">
            <i className="fab fa-github"/>
          </a>
        </li>
      </ul>
    );
  }
}

export default Socials
