import React from 'react';
import Helmet from 'react-helmet';
import Header from './header';
import Footer from './footer';

import '../styles/bootstrap.scss';
import '../styles/main.scss';

class DefaultLayout extends React.Component {
  render() {
    return (
      <div>
        <Helmet
          title={'temtum Cryptocurrency TEM'}
          meta={[
            {
              name: 'viewport',
              content: 'initial-scale=0.8, width=device-width, user-scalable=yes, minimum-scale=0.8, maximum-scale=2.0, shrink-to-fit=yes'
            },
            {
              name: 'description',
              content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
            },
            {
              name: 'twitter:card',
              content: 'summary'
            }, {
              name: 'twitter:site',
              content: '@wearetemtum'
            }, {
              name: 'twitter:creator',
              content: '@wearetemtum'
            }, {
              name: 'twitter:title',
              content: 'temtum Cryptocurrency TEM'
            }, {
              name: 'twitter:description',
              content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
            }, {
              name: 'twitter:image',
              content: 'https://temtum.com/social/social-card.png'
            }, {
              property: 'og:title',
              content: 'temtum Cryptocurrency TEM'
            }, {
              property: 'og:type',
              content: 'website'
            }, {
              property: 'og:description',
              content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
            }, {
              property: 'og:url',
              content: 'https://temtum.com/'
            }, {
              property: 'og:image',
              content: 'https://temtum.com/social/social-card.png'
            }, {
              name: 'og:site_name',
              content: 'temtum'
            }, {
              name: 'fb:admins',
              content: 'Facebook numeric ID'
            }
          ]}
        >
          <link rel='preload' href="//fonts.googleapis.com/css?family=Roboto:300,400,500,600" as="font" type="font/woff" crossorigin="anonymous" />
          <link rel="stylesheet" type="text/css" as="style" href="//use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous" />
          <html lang="en"/>
        </Helmet>
        <Header/>
        { this.props.children }
        <Footer/>
      </div>
    )
  }
}

export default DefaultLayout;
