import React, { Component } from 'react';
import ReactDOM from 'react-dom'; // eslint-disable-line no-unused-vars
import { Link } from 'gatsby';

import logo from '../img/temtum-logo-main-dark.png';
import $ from 'jquery';
import AOS from 'aos';

import 'aos/dist/aos.css';

if ( typeof window !== 'undefined' ) {
  window.$ = window.jQuery = require( 'jquery' );
  window.Bootstrap = require( 'bootstrap' );
}

class Header extends Component {
  constructor(props){
    super(props);

    this.state = {
      isToggle: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(){
    this.setState({isToggle: !this.state.isToggle});
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    AOS.init();


    if ( typeof window !== 'undefined' ) {
      window.jQuery = $;
      window.$ = $;
    }

    // Overrides default menu hover behaviour so link is clickable
    $( '.navbar .dropdown > a.livelink' ).click( function () {
      window.location.href = this.href;
    } );

  }


  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isToggle: false
      });
    }
  };

  render() {
    let burgerClassName = this.state.isToggle ? "closeMenu" : "showMenu";
    let menuClassName = this.state.isToggle ? "showMenu open-nav" : "closeMenu close-nav";

    return ( <div className="skipScroll">
      <nav className="navbar navbar-expand navbar-dark hd-temtum flex-column flex-md-row skipScroll ">
        <div className="container">
          <Link className="lg-ml-5 navbar-brand flex-sm-grow-1X flex-md-grow-1 flex-lg-grow-1" to="/">
            <img src={logo} alt="temtum Logo" className=""/>
          </Link>
          <div className={'navbar-nav-scroll ml-md-auto d-none d-md-flex collapseX navbar-collapseX ' + menuClassName} id="navbarCollapse">
            <ul className="navbar-nav bd-navbar-nav flex-row">
              <li className="nav-item dropdown">
                <div className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  What is temtum?
                </div>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown09">
                  <Link className="dropdown-item scrollX" to="/#advancement">
                    Advancement
                  </Link>
                  <Link className="dropdown-item scrollX" to="/#research">
                    Research
                  </Link>
                  <Link className="dropdown-item scrollX" to="/#white-paper">
                    White Paper
                  </Link>
                  <Link className="dropdown-item scrollX" to="/#roadmap">
                    Roadmap
                  </Link>
                  <a className="dropdown-item scrollX" href="https://test.lab.temtum.com">
                    TPS demo
                  </a>
                </div>
              </li>
              <li>
                <Link to="/platform" className="nav-link">
                  Platform
                </Link>
              </li>
              <li>
                <Link to="/beacon" className="nav-link">
                  Beacon
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link to="/developers" className="nav-link dropdown-toggle livelink" id="dropdowndev" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-target="dropdowndev">
                  Developers
                </Link>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdowndev" id="dropdowndev">
                  <Link className="dropdown-item " to="/use-cases">
                    Use Cases
                  </Link>
                  <a className="dropdown-item" href="/developers/docs">
                    API Documentation
                  </a>
                  <a className="dropdown-item" href="https://explorer.temtum.com/">
                    Explorer
                    <i className="fas fa-external-link-alt ml-1"></i>
                  </a>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link to="/about" className="nav-link dropdown-toggle livelink" id="dropdownabout" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-target="dropdowndev">
                  About
                </Link>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownabout" id="dropdownabout">
                  <Link className="dropdown-item " to="https://www.temtumgroup.com/about/">
                    About Us
                  </Link>
                  <Link className="dropdown-item" to="/learn">
                    Learn
                  </Link>
                  <Link className="dropdown-item" to="/tokenomics">
                    Tokenomics
                  </Link>
                  <Link className="dropdown-item" to="/press">
                    Press
                  </Link>
                  <Link className="dropdown-item" to="/research">
                    Research
                  </Link>
                  <Link className="dropdown-item" to="/reports">
                    Reports
                  </Link>
                  <Link className="dropdown-item" to="/enterprise">
                    Enterprise
                  </Link>
                  <Link className="dropdown-item" to="/faq">
                    FAQ
                  </Link>
                </div>
              </li>
              <li>
                <Link to="/wallets" className="nav-link">
                  Wallets
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex">
            <ul className="navbar-nav bd-navbar-nav flex-row">
              <li>
                <Link to="/whitepaper" className="btn btn-outline-light ml-5" data-toggle="tooltip" data-html="false" title="View or Download our White Paper">
                  White Paper
                </Link>
              </li>
              <li>
                <div data-toggle="tooltip" data-html="true" title="Register your interest for temtum">
                  <div className="btn btn-secondary ml-2" data-toggle="modal" data-target="#modalWhitelist" >
                    Mailing List
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div id="menu-toggle" className={burgerClassName} onClick={this.toggleMenu}>
            <div id="menu-icon">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </nav>
    </div> )
  }
}

export default Header;
