import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/temtum-logo-main-dark.png';
import cookie from '../img/cookie.png';

import CookieConsent from 'react-cookie-consent';
import Socials from '../components/Socials';


class Footer extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showMailingList: false
    };

    this.viewMailingList = this.viewMailingList.bind(this);
  }

  viewMailingList(){
    this.setState({showMailingList: !this.state.showMailingList});
  }

  render() {
    return (
      <div className="skipScroll">
        <section id="" className="skipScroll bg-color-fade p-0">
          <div className="bg-wallet-footer">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-6 mx-auto text-center">
                  <h5 className="display-2 text-blueX mb-3 mt-5X text-white">Genesis block created 4pm 11/07/19 - <span
                    className="font-weight-bold text-white">temtum is live</span></h5>
                  <h5 className="mb-5 display-5 text-white">Now available to trade on:</h5>
                </div>
              </div>
              <div className="row fl-wrapper text-center align-items-centerX">
                <div className="col col-12 col-sm-12 col-md-3 mb-3">
                  <div className="card card-body">
                    <div className="card-img-wrapper">
                      <img src={require('../img/partners/partner-liquid.png')} height="100" alt=""/>
                    </div>

                    <h5 className="card-title">
                      <a href="https://app.liquid.com/exchange/TEMUSDC" target="_blank" rel="noopener noreferrer">TEM/USDT</a>
                    </h5>
                  </div>
                </div>

                <div className="col col-12 col-sm-12 col-md-3 mb-3">
                  <div className="card card-body">
                    <div className="card-img-wrapper">
                      <img src={require('../img/partners/partner-liquid.png')} height="100" alt=""/>
                    </div>

                    <h5 className="card-title">
                      <a href="https://app.liquid.com/exchange/TEMBTC" target="_blank" rel="noopener noreferrer">TEM/BTC</a>
                    </h5>
                  </div>
                </div>

                <div className="col col-12 col-sm-12 col-md-3 mb-3">
                  <div className="card card-body">
                    <div className="card-img-wrapper">
                      <img src={require('../img/partners/partner-probit.png')} height="100" alt=""/>
                    </div>

                    <h5 className="card-title">
                      <a href="https://www.probit.com/app/exchange/TEM-BTC" target="_blank" rel="noopener noreferrer">TEM/BTC</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer nopad ft-temtum bg-blue-3 pb-5 text-white skipScroll">
          <div className="pt-5 pb-3 my-5X">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 footers-one">
                  <div className="footers-logo text-center">
                    <Link className="v-center" to="/">
                      <img
                        src={logo}
                        alt="temtum Logo"
                        className="navbar-brand"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-9 mx-auto footers-two">
                  <ul className="nav navbar-navX bd-navbar-nav flex-row justify-content-end footer-links">
                    <li className="nav-item">
                      <Link to="/about" className="nav-link">About</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/platform" className="nav-link">Platform</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/faq" className="nav-link">Faq</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/beacon" className="nav-link">Beacon</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/developers" className="nav-link">Developers</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 text-left">
                <div className="social-network social-circle pl-2">
                  <Socials/>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 text-right">
                <ul className="nav navbar-navX bd-navbar-nav flex-row justify-content-end footer-links">
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/terms" className="nav-link">
                      Website Terms
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/wallet-terms" className="nav-link">
                      temtum &amp; Wallet Terms
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/privacy" className="nav-link">
                      Privacy Notice
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container mt-5 mb-5 text-muted">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 flex">
                <p className="grey small">
                  Learn how we collect, use and share your data and how we use cookies and similar technology in our
                  Privacy Policy.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-5 flex">
                <div className="justify-content-start  mr-5">
                  <p className="grey small"></p>
                </div>
                <div className="justify-content-end">
                  <p className="grey small">&copy;&nbsp;temtum, {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div
          className="modal fade"
          id="modalWhitelist"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body bg-white bg-wallet">

                <div className="video-overlay" />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div id="" className="">
                  <div className="p-5 p-lg-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-6 mx-auto">
                            <div className="boxX">
                              <h3 className="display-2 mb-4">
                                Subscribe for news about temtum
                              </h3>
                              <p className="mb-5">
                                Let us know your email address, and we
                                will be in touch with news about temtum.
                              </p>

                              <hr className="mt-5" />
                              <p className="grey small">
                                Learn how we collect, use and share your data, and how we use cookies and similar technology in our
                                <a
                                  href="/privacy"
                                  className="ml-1 mr-1 text-grey"
                                >
                                  Privacy Policy
                                </a>
                              </p>

                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-5 mx-auto">
                            <form
                              action="https://app.getresponse.com/add_subscriber.html"
                              acceptCharset="utf-8"
                              method="post"
                              className=""
                            >
                              <div className="form-inlineX mb-3">
                                <label className="mr-5">Name:</label>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control form-control-smX"
                                  id="name"
                                  placeholder="Enter name"
                                  required
                                  pattern="[^0-9]+"
                                />
                              </div>
                              <div className="form-inlineX my-3">
                                <label className="mr-5">Email:</label>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control form-control-smX"
                                  id="email"
                                  placeholder="Enter Email"
                                  required
                                />
                              </div>
                              <div className="form-inlineX my-3">
                                <label className="mr-5 smlX">
                                  Country:
                                </label>

                                <select
                                  name="custom_country"
                                  defaultValue="United Kingdom"
                                  className="form-control"
                                  required
                                >
                                  <option value="Afghanistan">
                                    Afghanistan
                                  </option>
                                  <option value="Aland Islands">
                                    Aland Islands
                                  </option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">
                                    American Samoa
                                  </option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antarctica">
                                    Antarctica
                                  </option>
                                  <option value="Antigua and Barbuda">
                                    Antigua and Barbuda
                                  </option>
                                  <option value="Argentina">
                                    Argentina
                                  </option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">
                                    Australia
                                  </option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">
                                    Azerbaijan
                                  </option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">
                                    Bangladesh
                                  </option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire, Sint Eustatius and Saba">
                                    Bonaire, Sint Eustatius and Saba
                                  </option>
                                  <option value="Bosnia and Herzegovina">
                                    Bosnia and Herzegovina
                                  </option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Bouvet Island">
                                    Bouvet Island
                                  </option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Territory">
                                    British Indian Ocean Territory
                                  </option>
                                  <option value="Brunei Darussalam">
                                    Brunei Darussalam
                                  </option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">
                                    Burkina Faso
                                  </option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cabo Verde">
                                    Cabo Verde
                                  </option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Cayman Islands">
                                    Cayman Islands
                                  </option>
                                  <option value="Central African Republic">
                                    Central African Republic
                                  </option>
                                  <option value="Chad">Chad</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">
                                    Christmas Island
                                  </option>
                                  <option value="Cocos (Keeling) Islands">
                                    Cocos (Keeling) Islands
                                  </option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Congo, the democratic republic of the">
                                    Congo, the democratic republic of the
                                  </option>
                                  <option value="Cook Islands">
                                    Cook Islands
                                  </option>
                                  <option value="Costa Rica">
                                    Costa Rica
                                  </option>
                                  <option value="Cote d'Ivoire">
                                    Cote d'Ivoire
                                  </option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curacao">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">
                                    Czech Republic
                                  </option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">
                                    Dominican Republic
                                  </option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">
                                    El Salvador
                                  </option>
                                  <option value="Equatorial Guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands (Malvinas)">
                                    Falkland Islands (Malvinas)
                                  </option>
                                  <option value="Faroe Islands">
                                    Faroe Islands
                                  </option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">
                                    French Guiana
                                  </option>
                                  <option value="French Polynesia">
                                    French Polynesia
                                  </option>
                                  <option value="French Southern Territories">
                                    French Southern Territories
                                  </option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">
                                    Gibraltar
                                  </option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">
                                    Greenland
                                  </option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">
                                    Guadeloupe
                                  </option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">
                                    Guatemala
                                  </option>
                                  <option value="Guernsey">Guernsey</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guinea-bissau">
                                    Guinea-bissau
                                  </option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Heard Island and Mcdonald Islands">
                                    Heard Island and Mcdonald Islands
                                  </option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">
                                    Hong Kong
                                  </option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="India">India</option>
                                  <option value="Indonesia">
                                    Indonesia
                                  </option>
                                  <option value="Iran, Islamic republic of">
                                    Iran, Islamic republic of
                                  </option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of man">
                                    Isle of man
                                  </option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jersey">Jersey</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">
                                    Kazakhstan
                                  </option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea, democratic people's republic of">
                                    Korea, democratic people's republic of
                                  </option>
                                  <option value="Korea, republic of">
                                    Korea, republic of
                                  </option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">
                                    Kyrgyzstan
                                  </option>
                                  <option value="Lao people's democratic republic">
                                    Lao people's democratic republic
                                  </option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">
                                    Liechtenstein
                                  </option>
                                  <option value="Lithuania">
                                    Lithuania
                                  </option>
                                  <option value="Luxembourg">
                                    Luxembourg
                                  </option>
                                  <option value="Macao">Macao</option>
                                  <option value="Macedonia, the former yugoslav republic of">
                                    Macedonia, the former yugoslav republic
                                    of
                                  </option>
                                  <option value="Madagascar">
                                    Madagascar
                                  </option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">
                                    Marshall Islands
                                  </option>
                                  <option value="Martinique">
                                    Martinique
                                  </option>
                                  <option value="Mauritania">
                                    Mauritania
                                  </option>
                                  <option value="Mauritius">
                                    Mauritius
                                  </option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Micronesia, federated states of">
                                    Micronesia, federated states of
                                  </option>
                                  <option value="Moldova, republic of">
                                    Moldova, republic of
                                  </option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montenegro">
                                    Montenegro
                                  </option>
                                  <option value="Montserrat">
                                    Montserrat
                                  </option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">
                                    Mozambique
                                  </option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Namibia">Namibia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                  <option value="Netherlands Antilles">
                                    Netherlands Antilles
                                  </option>
                                  <option value="New Caledonia">
                                    New Caledonia
                                  </option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                  <option value="Nicaragua">
                                    Nicaragua
                                  </option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">
                                    Norfolk Island
                                  </option>
                                  <option value="Northern Mariana Islands">
                                    Northern Mariana Islands
                                  </option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau">Palau</option>
                                  <option value="Palestine, State of">
                                    Palestine, State of
                                  </option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">
                                    Papua New Guinea
                                  </option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Philippines">
                                    Philippines
                                  </option>
                                  <option value="Pitcairn">Pitcairn</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">
                                    Puerto Rico
                                  </option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Réunion">Réunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russian Federation">
                                    Russian Federation
                                  </option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="Saint Barthélemy">
                                    Saint Barthélemy
                                  </option>
                                  <option value="Saint Helena, Ascension and Tristan da Cunha">
                                    Saint Helena, Ascension and Tristan da
                                    Cunha
                                  </option>
                                  <option value="Saint Kitts and Nevis">
                                    Saint Kitts and Nevis
                                  </option>
                                  <option value="Saint Lucia">
                                    Saint Lucia
                                  </option>
                                  <option value="Saint Martin">
                                    Saint Martin
                                  </option>
                                  <option value="Saint Pierre and Miquelon">
                                    Saint Pierre and Miquelon
                                  </option>
                                  <option value="Saint Vincent and the Grenadines">
                                    Saint Vincent and the Grenadines
                                  </option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="San Marino">
                                    San Marino
                                  </option>
                                  <option value="Sao Tome and Principe">
                                    Sao Tome and Principe
                                  </option>
                                  <option value="Saudi Arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Serbia">Serbia</option>
                                  <option value="Seychelles">
                                    Seychelles
                                  </option>
                                  <option value="Sierra Leone">
                                    Sierra Leone
                                  </option>
                                  <option value="Singapore">
                                    Singapore
                                  </option>
                                  <option value="Sint Maarten">
                                    Sint Maarten
                                  </option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">
                                    Solomon Islands
                                  </option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">
                                    South Africa
                                  </option>
                                  <option value="South Georgia and the South Sandwich Islands">
                                    South Georgia and the South Sandwich
                                    Islands
                                  </option>
                                  <option value="South Sudan">
                                    South Sudan
                                  </option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">
                                    Sri Lanka
                                  </option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Svalbard and Jan Mayen">
                                    Svalbard and Jan Mayen
                                  </option>
                                  <option value="Swaziland">
                                    Swaziland
                                  </option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">
                                    Switzerland
                                  </option>
                                  <option value="Syrian Arab Republic">
                                    Syrian Arab Republic
                                  </option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">
                                    Tajikistan
                                  </option>
                                  <option value="Tanzania, United Republic Of">
                                    Tanzania, United Republic Of
                                  </option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Timor-leste">
                                    Timor-leste
                                  </option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad and Tobago">
                                    Trinidad and Tobago
                                  </option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">
                                    Turkmenistan
                                  </option>
                                  <option value="Turks and Caicos Islands">
                                    Turks and Caicos Islands
                                  </option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">
                                    United Arab Emirates
                                  </option>
                                  <option value="United Kingdom">
                                    United Kingdom
                                  </option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  <option value="United States Minor Outlying Islands">
                                    United States Minor Outlying Islands
                                  </option>
                                  <option value="Uruguay">Uruguay</option>
                                  <option value="Uzbekistan">
                                    Uzbekistan
                                  </option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">
                                    Vatican City State
                                  </option>
                                  <option value="Venezuela, Bolivarian Republic Of">
                                    Venezuela, Bolivarian Republic Of
                                  </option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands, British">
                                    Virgin Islands, British
                                  </option>
                                  <option value="Virgin Islands, U.S.">
                                    Virgin Islands, U.S.
                                  </option>
                                  <option value="Wallis and Futuna">
                                    Wallis and Futuna
                                  </option>
                                  <option value="Western Sahara">
                                    Western Sahara
                                  </option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                              </div>
                              <label
                                htmlFor="webform_consent#fmw_0"
                                className="small text-grey my-3"
                              >
                                <input
                                  id="webform_consent#fmw_0"
                                  type="checkbox"
                                  name="webform[consent#fMw-ver#cw7]"
                                  value="true"
                                  className="mr-1"
                                  required
                                />
                                By signing up to our mailing list you accept
                                our
                                <a
                                  href="/terms"
                                  className="ml-1 mr-1 text-grey underline"
                                >
                                  Website Terms
                                </a>
                                and
                                <a
                                  href="/privacy"
                                  className="ml-1 mr-1 text-grey underline"
                                >
                                  Privacy Policy
                                </a>
                              </label>
                              <label
                                htmlFor="webform_consent#fzz_0"
                                className="small text-grey mb-3"
                              >
                                <input
                                  id="webform_consent#fzz_0"
                                  type="checkbox"
                                  name="webform[consent#fZZ-ver#ckT]"
                                  value="true"
                                  className="mr-1"
                                  required
                                />
                                I agree that I am not resident of any of the
                                following countries: USA, China or Russia.
                              </label>
                              <br />
                              <input
                                type="hidden"
                                name="thankyou_url"
                                value="https://temtum.com/mailing-list-confirm"
                              />
                              <input
                                type="hidden"
                                name="campaign_token"
                                value="6A1RG"
                              />
                              <input
                                type="submit"
                                className="btn btn-secondary mb-2"
                                value="Subscribe"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerX">
          <CookieConsent
            location="bottom"
            buttonText="OK, I understand"
            cookieName="temtumCookie"
            style={{ background: '#000033', color: '#fff', textAlign: 'center', display: 'block' }}
            buttonStyle={{
              background: '#ff0033',
              color: '#ffffff',
              fontSize: '13px',
              borderRadius: '2px',
              padding: '5px 20px',
              margin: '0 15px 15px 15px',
            }}
            buttonClass="test"
            expires={7}
          >
          <span className="container">
            <img
              className="img-fluid d-inline-block mb-0 mr-2"
              src={cookie}
              alt="We use cookies"
            />
            <span style={{ fontSize: '13px' }}>
              This website uses cookies. By
              continuing to use this website you are consenting to our use of
              cookies. <a href="./privacy" style={{
              color: '#ffffff',
              textDecoration: 'underline',
            }}>Learn more in our Privacy Policy</a>.
            </span>
          </span>
          </CookieConsent>
        </div>


      </div>
    )
  }
}

export default Footer;
